<!--  -->
<template>
  <div class="wrap">
    <p class="psText colorhui">
      {{ explain }}
    </p>
    <van-collapse v-model="activePatientId" :accordion="true">
      <van-collapse-item
        v-for="item in cardContentList"
        :name="item.patientId"
        :key="item.patientId"
      >
        <template #title>
          <div class="left">
            <span>
              <encryption-component
                label="卡号:"
                :textNum="item.patientId"
                :start="3"
              />
            </span>
            <span class="yibao" v-show="item.status === 2">医保</span>
            <span class="zifei" v-show="item.status === 1">自费</span>
            <van-icon
              v-show="activePatientId === item.patientId"
              class="activeColor"
              name="success"
            />
          </div>
        </template>
        <div class="cardContent">
          姓名: {{ item.name }}<br />
          <encryption-component
            label="身份证号:"
            :textNum="item.idNo"
            :start="4"
          />
        </div>
      </van-collapse-item>
    </van-collapse>
    <van-button type="primary" @click="onSubmit">已选好，完成绑定</van-button>
    <div class="tipsContent">
      <p>温馨提示：</p>
      <ul>
        <li v-for="(item, i) of tips" :key="i" class="colorhui">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import encryptionComponent from "@/components/util/encryptionComponent";
export default {
  name: "addCardThree",
  components: { encryptionComponent },
  data() {
    return {
      // 说明文字
      explain:
        "请在下方选择您就医服务使用的卡号进行绑定，排列顺序为按照最新就诊事件倒叙排列，为保证就诊信息完整，建议绑定为最新就诊事件的就诊卡。",
      // 提示文字
      tips: [
        "同一身份证号仅可以绑定1张院内就诊卡，请仔细核实卡号信息后予以绑定。",
        "北京医保因费用结算时需要实体卡插卡结算，就诊时若使用二维码为全额缴费，不支持医保报销结算。",
        "就诊人绑定卡号用于医院建档信息匹配及证件匹配，请选择就诊人在医院的有效卡号。",
      ],
      // 用户注册信息
      userData: "",
      // 就诊卡
      cardContentList: [],
      activePatientId: "",
      // 健康卡授权码
      healthCode: "",
      // 根据授权码查出的用户信息
      healthUserData: {},
      // 用于区分是正常注册流程还是跨院绑定 true为注册 false为绑定
      isRegister: true,
    };
  },
  created() {
    let healthCode = (this.healthCode = this.$route.query.healthCode);
    // 有值说明是跨行绑定
    if (healthCode) {
      this.getCardInfo(healthCode);
    } else {
      if (!sessionStorage.getItem("userData")) {
        sessionStorage.removeItem("userData");
        sessionStorage.setItem("backRoute", this.$route.fullPath);
        this.$router.push("/addCardOne");
        return;
      }
      // 注册标识默认为注册，无需更改
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      this.getPatid(this.userData);
    }
  },
  methods: {
    // 根据用户信息获取院内患者id
    getPatid(userData) {
      if (userData) {
        let postData = {
          name: userData.name,
          idCard: userData.idNumber,
        };
        this.$healthHttpService
          .get("api/search/patid", postData)
          .then((res) => {
            if (res.data) {
              Object.assign(this.cardContentList, res.data);
            }
          });
      }
    },
    // 根据healthCode获取卡信息
    getCardInfo(healthCode) {
      if (!healthCode) return;
      this.$healthHttpService
        .get("healthapi/api/search/bycode", { healthCode })
        .then((res) => {
          if (res.data) {
            // 将标识设置为跨行绑定流程
            this.isRegister = false;
            // 拿到授权码中的用户信息获取院内患者id进行绑定
            // 拿到患者id列表页面进行展示,用户选中id点击按钮进行绑定
            this.getPatid(res.data);
            Object.assign(this.healthUserData, res.data);
          }
        });
    },
    // 将卡信息注册或者绑定
    onSubmit() {
      if (!this.activePatientId) {
        this.$notify({
          type: "warning",
          message: "请在下方选择您就医服务使用的卡号进行绑定",
        });
      } else {
        // 判断流程 true为注册 false为跨行绑定
        if (this.isRegister) {
          let postData = { ...this.userData };
          let wechatCode = sessionStorage.getItem("wechatCode"); //微信身份码
          let openid = sessionStorage.getItem("openID"); //用户id
          postData.wechatCode = wechatCode;
          postData.openid = openid;
          postData.patid = this.activePatientId;
          postData.idType = "01";
          this.$healthHttpService
            .post("healthapi/api/health/register", postData)
            .then((res) => {
              sessionStorage.removeItem("wechatCode");
              if (res.data.qrCodeText) {
                sessionStorage.removeItem("userData");
                this.$router.push("/");
              }
            })
            .catch((err) => {
              this.$toast({
                message: err.response.data,
                duration: 500,
                onClose: () => {
                  this.$router.push("/");
                },
              });
            });
        } else {
          // 跨行绑定流程
          // activePatientId
          let postData = {
            HealthCardId: this.healthUserData.healthCardId,
            name: this.healthUserData.name,
            qrCodeText: this.healthUserData.qrCodeText,
            gender: this.healthUserData.gender,
            nation: this.healthUserData.nation,
            birthday: this.healthUserData.birthday,
            idNumber: this.healthUserData.idNumber,
            idType: this.healthUserData.idType,
            address: this.healthUserData.address,
            citycode: this.healthUserData.citycode,
            phone1: this.healthUserData.phone1,
            openid: sessionStorage.getItem("openID"),
            patid: this.activePatientId,
          };
          this.$healthHttpService
            .post("healthapi/api/health/bycode", postData)
            .then((res) => {
              // if (res.data) {
              //   sessionStorage.removeItem("userData");
              //   // 跳转二维码展示页面
              //   this.$router.push("/");
              // } else {
              //   this.$notify({
              //     type: "warning",
              //     message: "一键绑定失败",
              //   });
              // }
              console.log(res);
              sessionStorage.removeItem("userData");
              // 跳转二维码展示页面
              this.$router.push("/");
            })
            .catch(() => {
              this.$notify({
                type: "warning",
                message: "发生未知错误，绑定失败",
              });
            });
        }
      }
    },
  },
};
</script>
<style scoped>
.wrap {
  text-align: left;
  padding: 0 0.2rem 0.2rem 0.2rem;
}
.psText {
  font-size: 0.25rem;
}
.cardContent {
  width: 100%;
  height: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left {
  display: flex;
  align-items: center;
  font-size: 0.25rem;
}
.yibao,
.zifei {
  width: 0.6rem;
  height: 0.3rem;
  line-height: 0.3rem;
  font-size: 0.2rem;
  font-weight: bold;
  margin: 0 0.3rem;
  border-radius: 0.05rem;
  display: flex;
  justify-content: center;
}
.yibao {
  color: #3c44c0;
  background-color: #3c45c06b;
}
.zifei {
  color: #46b96c;
  background-color: #46b96c73;
}
.activeColor {
  color: #46b96c;
}
.tipsContent {
  font-size: 0.25rem;
}
.tipsContent li {
  margin-top: 0.2rem;
  padding-left: 0.2rem;
  position: relative;
}
.tipsContent li::after {
  content: "";
  position: absolute;
  top: 0.1rem;
  left: 0;
  border: 2px solid rgb(130, 130, 130);
  border-radius: 50%;
}
.cardContent {
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.25rem;
}
</style>
